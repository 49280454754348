
  <section id="hero" class="d-flex flex-column justify-content-end align-items-center">
    <div id="heroCarousel" data-bs-interval="5000" class="container carousel carousel-fade" data-bs-ride="carousel">
  
      
      <div class="carousel-item active">
        <div class="carousel-container">
          <h2 class="animate__animated animate__fadeInDown">Welcome to <span>NEXT IT BUSINESS</span></h2>
         
        </div>
      </div>
  
      <a class="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon bx bx-chevron-left" aria-hidden="true"></span>
      </a>
  
      <a class="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon bx bx-chevron-right" aria-hidden="true"></span>
      </a>
  
    </div>
  
  </section>
  
  <main id="main">
  
    <section id="about" class="about">
      <div class="container">
        <div class="row content" data-aos="fade-up">
          <div class="section-title" data-aos="zoom-out">
            <h2>About Us</h2>
            <p>Who we are</p>
          </div>
          <!-- <p class="description">Welcome to NEXT IT BUSINESS, a leading software company dedicated to providing innovative solutions to businesses worldwide. 
            With a passion for technology and a commitment to excellence, 
            we strive to empower organizations by leveraging the power of software and cutting-edge technologies.
          </p> -->
          <p class="description">
            Welcome to NEXT IT BUSINESS, a leading software company dedicated to providing innovative solutions to businesses worldwide. 
          With a passion for technology and a commitment to excellence, 
          we strive to empower organizations by leveraging the power of software and cutting-edge technologies.
          </p>
          <p class="description">
            At NEXT IT BUSINESS, our mission is to revolutionize the way businesses operate by delivering tailored software solutions that enhance productivity, 
            efficiency, and overall performance. We aim to be the trusted partner of choice, enabling our clients to thrive in the ever-evolving digital landscape.
          </p>
          <!-- <div class="col-lg-6">
            <p>
              Welcome to NEXT IT BUSINESS, a leading software company dedicated to providing innovative solutions to businesses worldwide. 
            With a passion for technology and a commitment to excellence, 
            we strive to empower organizations by leveraging the power of software and cutting-edge technologies.
            </p>
            <p>
              At NEXT IT BUSINESS, our mission is to revolutionize the way businesses operate by delivering tailored software solutions that enhance productivity, 
              efficiency, and overall performance. We aim to be the trusted partner of choice, enabling our clients to thrive in the ever-evolving digital landscape.
            </p>
            <ul>
              <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
              <li><i class="ri-check-double-line"></i> Duis aute irure dolor in reprehenderit in voluptate velit</li>
              <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
            </ul>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0">
            <div class="section-title" data-aos="zoom-out">
              <h2>Our Mission</h2>
              <p>Who we are</p>
            </div>
            <p>
              At NEXT IT BUSINESS, our mission is to revolutionize the way businesses operate by delivering tailored software solutions that enhance productivity, 
              efficiency, and overall performance. We aim to be the trusted partner of choice, enabling our clients to thrive in the ever-evolving digital landscape.
            </p>
            <a href="#" class="btn-learn-more">Learn More</a>
          </div> -->
        </div>
      </div>
      <div class="container mt-5">
        <div class="row content" data-aos="fade-up">
          <div class="col-lg-6">
            <div class="section-title" data-aos="zoom-out">
              <h2>Our Expertise</h2>
            </div>
            <p>
              With a team of highly skilled professionals, we possess extensive expertise across a wide range of technologies and domains. 
              Our talented software engineers, designers, and consultants work collaboratively to create custom solutions that address 
              our clients' unique challenges. Whether it's web development, mobile applications, enterprise software, or cloud-based solutions, 
              we have the skills and knowledge to deliver exceptional results.
            </p>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0">
            <div class="section-title" data-aos="zoom-out">
              <h2>Client-Centric Approach</h2>
              <!-- <p>Who we are</p> -->
            </div>
            <p>
              At the core of our business philosophy lies a deep commitment to our clients' success. We believe in building long-term partnerships based on trust, transparency, and open communication. By thoroughly understanding our clients' goals and objectives, we tailor our solutions to their specific needs, ensuring maximum value and a competitive edge in the market.
            </p>
          </div>
        </div>
      </div>
      <div class="container mt-5">
        <div class="row content" data-aos="fade-up">
          <div class="col-lg-6">
            <div class="section-title" data-aos="zoom-out">
              <h2>Innovation and Continuous Improvement</h2>
            </div>
            <p>
              In the fast-paced world of technology, innovation is key to staying ahead. We foster a culture of continuous learning, encouraging our team to explore new technologies, methodologies, and trends. By staying at the forefront of industry advancements, we can consistently deliver state-of-the-art solutions that enable our clients to adapt, grow, and thrive.
            </p>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0">
            <div class="section-title" data-aos="zoom-out">
              <h2>Quality and Reliability</h2>
              <!-- <p>Who we are</p> -->
            </div>
            <p>
              We are committed to delivering top-quality software solutions that meet the highest industry standards. Our rigorous quality assurance processes, thorough testing methodologies, and meticulous attention to detail ensure that every product we deliver is robust, reliable, and user-friendly.
            </p>
          </div>
        </div>
      </div>
    </section>
    
    <!-- <section id="cta" class="cta">
      <div class="container">
  
        <div class="row" data-aos="zoom-out">
          <div class="col-lg-9 text-center text-lg-start">
            <h3>Call To Action</h3>
            <p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
          <div class="col-lg-3 cta-btn-container text-center">
            <a class="cta-btn align-middle" href="#">Call To Action</a>
          </div>
        </div>
  
      </div>
    </section> -->
    <section id="services" class="services">
      <div class="container">
        <div class="section-title" data-aos="zoom-out">
          <h2>Services</h2>
          <p>What we do offer</p>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="icon-box" data-aos="zoom-in-left">
              <!-- <div class="icon"><i class="bi bi-briefcase" style="color: #ff689b;"></i></div> -->
              <h4 class="title"><a href="">IT Infrastructure Services</a></h4>
              <!-- <p class="description"></p> -->
              <ul>
                <li><i class="ri-check-double-line"></i> Network Design and Implementation</li>
                <li><i class="ri-check-double-line"></i> Server Virtualization and Consolidation</li>
                <li><i class="ri-check-double-line"></i> Cloud Infrastructure Management</li>
                <!-- <li><i class="ri-check-double-line"></i> Data Center Solutions</li> -->
                <li><i class="ri-check-double-line"></i> IT Security and Compliance</li>
                <li><i class="ri-check-double-line"></i> IT Infrastructure Monitoring and Support</li>
              </ul>
              <!-- <div class="cta-btn-container text-center">
                <a class="cta-btn align-middle" routerLink="it-infra">Click here for more</a>
              </div> -->
            </div>
          </div>
          <div class="col-lg-4 col-md-6  mt-lg-0 ">
          
            <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="200">
              <!-- <div class="icon"><i class="bi bi-binoculars" style="color:#41cf2e;"></i></div> -->
              <h4 class="title"><a href="">CCTV Security System Solutions</a></h4>
              <ul>
                <li><i class="ri-check-double-line"></i> Security Assessment and Consultation</li>
                <li><i class="ri-check-double-line"></i> Customized System Design</li>
                <li><i class="ri-check-double-line"></i> High-Quality Camera Installation</li>
                <!-- <li><i class="ri-check-double-line"></i> Video Management Systems (VMS)</li> -->
                <li><i class="ri-check-double-line"></i> Remote Monitoring and Real-time Alerts</li>
                <li><i class="ri-check-double-line"></i> System Maintenance and Support</li>
                <!-- <li><i class="ri-check-double-line"></i> Integration with Security Systems</li> -->
              </ul>
            </div>
          </div>
  
          <div class="col-lg-4 col-md-6">
            <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
              <!-- <div class="icon"><i class="bi bi-card-checklist" style="color: #3fcdc7;"></i></div> -->
              <h4 class="title"><a href="">Software Development Services</a></h4>
              <ul>
                <li><i class="ri-check-double-line"></i> Custom Software Development</li>
                <li><i class="ri-check-double-line"></i> Web Application Development</li>
                <li><i class="ri-check-double-line"></i> Mobile Application Development</li>
                <li><i class="ri-check-double-line"></i> Software Integration and API Development</li>
                <li><i class="ri-check-double-line"></i> Software Testing and Quality Assurance</li>
                <li><i class="ri-check-double-line"></i> Software Maintenance and Support</li>
              </ul>
            </div>
          </div>
  
       
          <div class="col-lg-6 col-md-6 mt-5" style="display: none;">
            <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="500">
              <!-- <div class="icon"><i class="bi bi-clock" style="color: #4680ff;"></i></div> -->
              <h4 class="title"><a href="">Web Hosting Services</a></h4>
              <ul>
                <li><i class="ri-check-double-line"></i> Shared Hosting</li>
                <li><i class="ri-check-double-line"></i> Virtual Private Server (VPS) Hosting</li>
                <li><i class="ri-check-double-line"></i> Dedicated Server Hosting</li>
                <li><i class="ri-check-double-line"></i> Cloud Hosting</li>
                <!-- <li><i class="ri-check-double-line"></i> Managed WordPress Hosting</li> -->
                <!-- <li><i class="ri-check-double-line"></i> E-commerce Hosting</li> -->
                <li><i class="ri-check-double-line"></i> SSL Certificates and Security</li>
                <li><i class="ri-check-double-line"></i> 24/7 Technical Support</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="services" class="services" style="display: none;">
      <div class="container">
        <div class="section-title" data-aos="zoom-out">
          <h2>Out Project</h2>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="icon-box" data-aos="zoom-in-left">
              <!-- <div class="icon"><i class="bi bi-briefcase" style="color: #ff689b;"></i></div> -->
              <h4 class="title"><a href="">Event Management Project</a></h4>
              <p class="description">Our Event Management Project is a comprehensive solution designed to streamline and simplify the planning, organization, and execution of events. Whether it's a corporate conference, trade show, wedding, or any other type of event, our project aims to provide a centralized platform that facilitates seamless collaboration, efficient task management, and enhanced attendee experiences.
              </p>
              <h5 class="title mt-3">Key Features of our Event Management Project:</h5>
              <ul>
                <li><i class="ri-check-double-line"></i> Event Planning and Budgeting</li>
                <li><i class="ri-check-double-line"></i> Vendor and Supplier Management</li>
                <li><i class="ri-check-double-line"></i> Online Registration and Ticketing</li>
                <li><i class="ri-check-double-line"></i> Agenda and Schedule Management</li>
                <li><i class="ri-check-double-line"></i> Event Marketing and Promotion</li>
                <li><i class="ri-check-double-line"></i> Onsite Event Management</li>
                <li><i class="ri-check-double-line"></i> Data Analytics and Reporting</li>
              </ul>
                <!-- <h6 class="title mt-3">Event Planning and Budgeting:</h6>
                <p class="description">
Our project offers robust features for event planning and budget management. You can create detailed event plans, set milestones, allocate resources, and track expenses, ensuring that all aspects of your event are carefully managed within the defined budget.
</p>
<h6 class="title mt-3">Vendor and Supplier Management:</h6>
<p class="description">
Efficiently manage your relationships with vendors and suppliers through our project's vendor management module. You can maintain a centralized database of vendors, send requests for proposals (RFPs), compare quotes, negotiate contracts, and track vendor performance, ensuring you have reliable partners to deliver the necessary services for your event.
</p>
<h6 class="title mt-3">Online Registration and Ticketing:</h6>
<p class="description">
Our project includes a user-friendly online registration and ticketing system that allows attendees to register for the event, select ticket types, and make secure online payments. Attendees can also access their tickets and event information through a personalized portal, providing a convenient and seamless registration experience.
</p>
<h6 class="title mt-3">Agenda and Schedule Management:</h6>
<p class="description">
Manage event agendas and schedules effortlessly using our project's scheduling tools. You can create multi-track agendas, assign speakers or presenters, and communicate schedule changes to attendees in real-time. This ensures that participants have up-to-date information on session timings, speakers, and other program details.
</p>
<h6 class="title mt-3">Event Marketing and Promotion:</h6>
<p class="description">
Promote your event effectively through integrated marketing features. Our project allows you to create event websites or landing pages, send personalized email invitations, and leverage social media integration to maximize your event's reach and generate buzz. You can also track marketing campaign performance and measure the effectiveness of your promotional efforts.
</p>
<h6 class="title mt-3">Onsite Event Management:</h6>
<p class="description">
Our project provides tools to manage onsite event operations smoothly. You can streamline attendee check-ins using barcode or QR code scanning, facilitate session tracking and access control, and manage exhibitor spaces. These features enhance the overall event experience and ensure efficient operations during the event.
</p>
<h6 class="title mt-3">Data Analytics and Reporting:</h6>
<p class="description">
  
Make data-driven decisions with comprehensive analytics and reporting capabilities. Our project collects valuable data throughout the event lifecycle, allowing you to analyze attendee demographics, track registration trends, measure event success, and gather feedback. These insights help you assess the impact of your event and make informed decisions for future planning.
</p> -->

<p class="description">
Our Event Management Project is designed to empower event organizers with the tools and features necessary to plan, manage, and execute successful events. By leveraging our comprehensive platform, you can streamline event operations, enhance attendee experiences, and achieve your event objectives efficiently.
              </p>
            </div>
          </div>
      </div>
    </div>
    </section>

    <section id="contact" class="contact">
      <div class="container">
  
        <div class="section-title" data-aos="zoom-out">
          <h2>Contact</h2>
          <p>Contact Us</p>
        </div>
  
        <div class="row mt-5">
  
          <div class="col-lg-4" data-aos="fade-right">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>Malad, Mumbai, 400097</p>
              </div>
  
              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>info.nextitbusiness@gmail.com</p>
              </div>
  
              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+91 8655673987 / 9833153749</p>
              </div>
  
            </div>
  
          </div>
  
          <div class="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left">
  
            <form action="forms/contact.php" method="post" role="form" class="php-email-form">
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required>
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit">Send Message</button></div>
            </form>
  
          </div>
  
        </div>
  
      </div>
    </section>
  </main>
  