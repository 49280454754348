import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ItInfraComponent } from './it-infra/it-infra.component';
import { CctvComponent } from './cctv/cctv.component';
import { SdComponent } from './sd/sd.component';
import { WebHostingComponent } from './web-hosting/web-hosting.component';
import { EventMgmtComponent } from './event-mgmt/event-mgmt.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    ContactUsComponent,
    ItInfraComponent,
    CctvComponent,
    SdComponent,
    WebHostingComponent,
    EventMgmtComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
