import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { ItInfraComponent } from './it-infra/it-infra.component';
import { HomeComponent } from './home/home.component';
import { CctvComponent } from './cctv/cctv.component';
import { SdComponent } from './sd/sd.component';
import { WebHostingComponent } from './web-hosting/web-hosting.component';

const routes: Routes = [
  {path:'',component:HomeComponent},
  {path:'home',component:HomeComponent},
  {path:'about',component:AboutUsComponent},
  {path:'it-infra',component:ItInfraComponent},
  {path:'cctv',component:CctvComponent},
  {path:'sd',component:SdComponent},
  {path:'web-hosting',component:WebHostingComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
